<template>
  <div class="single-list">
    <div class="thread-wrapp">
      <p class="thread-owner">
        <span class="type">{{ ownerType }}</span>
        <span class="created-at">{{ createdAt }}</span>
      </p>
      <p class="text">
        {{message.text}}
      </p>
      <div class="wrapp-attachmets">
        <ul class="files-list">
          <li v-for="(url, index) in message.upload_attachments" :key="index" class="filename-list">
            <img :src="url.content">
          </li>
        </ul>
      </div>
    </div>
  <!-- message{
  "id": 96,
  "request_id": 75,
  "created_at": "2021-12-20 11:28:20",
  "text": "The request is pending approval.",
  "owner_type": 1,
  "owner_name": null,
  "owner_id": 0,
  "auto": true,
  "internal": false,
  "attachments": []
}
   -->
  </div>
</template>

<script>
import dayjs from 'dayjs'

export default {
  name: 'SingleMessage',
  props: {
    message: {
      type: Object
    }
  },
  computed: {
    ownerType () {
      return this.message.owner_type === 1 ? this.$t('rma.messages.customer-care') : this.$t('rma.messages.customer-self')
    },
    createdAt () {
      return dayjs(this.message.created_at).format('DD/MM/YYYY HH:mm')
    }
  }
}
</script>
