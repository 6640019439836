<template>
  <div class="rma-thread-manager">
    <h3 class="title" v-t="'rma.messages.title'"></h3>
    <ul class="nav thread-list">
      <li class="single-thread" v-for="(message, index) in messages" :key="index">
        <single-message :message="message" />
      </li>
    </ul>
  </div>
</template>

<script>
import SingleMessage from 'theme/components/MyAccount/Rma/ThreadMessages/SingleMessage'

export default {
  name: 'ThreadManager',
  props: {
    messages: {
      type: Array,
      default: () => []
    }
  },
  components: {
    SingleMessage
  }
}
</script>
